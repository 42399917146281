@import '@/pages/va.module';

.free-box {
  height: 324px;
  width: 100%;
  overflow: hidden;
  .free-box-bg {
    //     background: url('../../../../public/images/bdfree.png') no-repeat;
    // background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 324px;
  }
  .free-content {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    // top: 0;
    // left: 0;
    // z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .free-center {
    text-align: center;
  }

  .free-box-title {
    width: 560px;
    padding: 0 0 38px 0;
    margin: 0 auto;
    font-size: 32px;
    font-family: 'Noto Sans Display Blod';
    font-weight: bold;
    color: #ffffff;
    line-height: 48px;
  }

  .get {
    background: #ff3e5f;
    padding: 15px 64px;
    margin: 0 auto 0;
    display: inline-block;
    font-size: 24px;
    // font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: #ffffff;
    line-height: 35px;
    border-radius: 35px;

    &:hover {
      opacity: 0.8 !important;
    }
  }
}
.pcBg{display: block;}
.mBg{display: none;}
@media screen and (max-width: 1024px) {
  .free-box{
        height: 300px;
        overflow: hidden;
      .free-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .free-box-title{
        font-size: 24px;
        line-height: 35px;
        width: auto;
      }
      .get{
        padding: 13px 45px;
        font-size: 16px;
        line-height: 25px;
      }
     
  }
  .mBg{display: block;}
  .pcBg{display: none;}
}